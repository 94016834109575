import axios from 'axios';
export const getID = async (email) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/user/${email}`);
      const data = await response.json();
      return data;
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getDevices = async (user_id) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/devices/${user_id}`);
      const data = await response.json();
      return data;
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getLatestPosition = async (traccar_id) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/latestPosition/${traccar_id}`);
      const data = await response.json();
      return data;
      
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getPositionDevices = async (user_id) => 
{
    try {
      const response = await fetch(`http://164.68.113.217:3821/api/positionDevices/${user_id}`);
      const data = await response.json();
      return data;
      
    } catch (error) 
    {
      console.log('Failed to fetch data:', error);
      return null;
    }
};

export const getHistory = async(traccar_id,date_init,date_end) =>
{
  try{
    const response = await fetch(`http://164.68.113.217:3821/api/history/${traccar_id}/${date_init}/${date_end}`);
    const data = await response.json();
    return data;
  }catch(error)
  {
    console.log('Failed to fetch data:', error);
    return null;
  }
}

export const getResume = async(traccar_id,date_init,date_end) =>
{
  try{
    const response = await fetch(`http://164.68.113.217:3821/api/resume/${traccar_id}/${date_init}/${date_end}`);
    const data = await response.json();
    return data;
  }catch(error)
  {
    console.log('Failed to fetch data:', error);
    return null;
  }
}

export const getReport = async(data) =>
{
  try{
    const response= await fetch('http://164.68.113.217:3821/api/Report', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dataClient: data,  // El array de IDs que deseas enviar
        
      })
    });
    const dataResponse = await response.json();
    return dataResponse;
  }catch(error)
  {
    console.log('Failed to fetch data:', error);
    return null;
  }    
}

export const getCommands = async (device_id, hash) => {
  try {
    const response = await fetch('http://164.68.113.217:3821/api/Commands', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hash: hash,
        device_id: device_id,
      }),
    });
    const dataResponse = await response.json();

    const keyWordsEncender = ['encender', 'encendido', 'reanudar'];
    const keyWordsApagar = ['apagar', 'apagado'];

    const Result = dataResponse.dataResponse
      .filter((item) => {
        const titleLower = item.title.toLowerCase();
        return (
          keyWordsEncender.some((word) => titleLower.includes(word)) ||
          keyWordsApagar.some((word) => titleLower.includes(word))
        );
      })
      .map((item) => {
        const titleLower = item.title.toLowerCase();
        let color = 'gray';
        if (keyWordsEncender.some((word) => titleLower.includes(word))) {
          color = '#80cf8e';
        } else if (keyWordsApagar.some((word) => titleLower.includes(word))) {
          color = '#ff6071';
        }
        return {
          ...item,
          color,
        };
      });

    return Result;
  } catch (error) {
    console.log('Failed to fetch data:', error);
    return [];
  }
};

export const sendCommand = async (device_id, type, hash) => {
  const baseUrl = "http://164.68.113.217/api/send_gprs_command";
  try {
    // Construir la URL con los parámetros codificados
    const url = `${baseUrl}?device_id=${device_id}&type=${type}&user_api_hash=${encodeURIComponent(hash)}`;
    const response = await axios.get(url, {
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.data) {
        return { error: "No se obtuvo respuesta del servicio API" };
    }
    return response.data;
  } catch (error) {
    return { error: error.response.data};
  }
};
