import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { getHistory, getDevices } from '../processing/getData';
import moment from 'moment-timezone';
import L from 'leaflet';

import banner from '../icons/actividad.png'; // Ajusta la ruta según tu estructura de carpetas
import './Activity.css';
import 'leaflet/dist/leaflet.css';

function formatDate(dateString) {
  const date = new Date(dateString);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

const getAddrees = async (latitude, longitude) => {
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    if (!response.ok) throw new Error('Error al obtener la dirección');
    const data = await response.json();
    return data.display_name;
  } catch (error) {
    console.error(error);
    return 'Dirección no disponible';
  }
};
// Configurar el ícono del marcador de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const Travel = () => {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mapPosition, setMapPosition] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [showMarkers, setShowMarkers] = useState(true);
  const [vehicleFilter, setVehicleFilter] = useState(''); // Nuevo estado para el filtro
  const [popupAddress, setPopupAddress] = useState('');

  function getDefaultStartDate() {
    const now= new Date();
    
    now.setHours(0, 0, 0, 0); // Establece la hora en 00:00:00
    now.setTime(now.getTime() - 5 * 60 * 60 * 1000);
    return now.toISOString().slice(0, 16); // Devuelve formato ISO compatible con datetime-local
  }

  function getDefaultEndDate() {
    const now = new Date();
    now.setHours(23, 59, 59, 0); // Establece la hora en 23:59:59
    now.setTime(now.getTime() - 5 * 60 * 60 * 1000);
    return now.toISOString().slice(0, 16); // Devuelve formato ISO compatible con datetime-local
  }

  useEffect(() => {
    setStartDate(getDefaultStartDate());
    setEndDate(getDefaultEndDate());
  }, []);

  useEffect(() => {
    const fetchVehicles = async () => {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        alert('No hay user_id almacenado en localStorage');
        return;
      }

      try {
        const devicesData = await getDevices(userId);
        if (devicesData && devicesData.devices) {
          setVehicles(devicesData.devices);
          setFilteredVehicles(devicesData.devices); // Inicializa los vehículos filtrados
        }
      } catch (error) {
        console.error('Error al obtener los dispositivos:', error);
      }
    };

    fetchVehicles();
  }, []);

  const handleVehicleChange = (e) => {
    setSelectedVehicle(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = async () => {
    setError(null);
    if (!selectedVehicle || !startDate || !endDate) {
      alert('Por favor, selecciona un vehículo y un rango de fechas.');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start >= end) {
      alert('La fecha de inicio debe ser menor que la fecha de fin.');
      return;
    }

    const diffHours = (end - start) / (1000 * 60 * 60);
    if (diffHours > 48) {
      alert('El rango de fechas no puede ser mayor a 48 horas.');
      return;
    }

    setLoading(true);

    if (showMap) {
      setShowMap(false);
      setMapPosition(null);
    }

    try {
      const adjustedStart = new Date(start.getTime() - (start.getTimezoneOffset() * 60 * 1000));
      const adjustedEnd = new Date(end.getTime() - (end.getTimezoneOffset() * 60 * 1000));
      const formattedStart = adjustedStart.toISOString().slice(0, 19).replace('T', ' ');
      const formattedEnd = adjustedEnd.toISOString().slice(0, 19).replace('T', ' ');
      const history = await getHistory(selectedVehicle, formattedStart, formattedEnd);
      if (history && history.history) {
        setHistoryData(history.history);
        if (history.history.length > 0) {
          setMapPosition([history.history[0].latitude, history.history[0].longitude]);
          setShowMap(true);
        }
      }
    } catch (error) {
      setError('Error al obtener el historial.');
      console.error('Error al obtener el historial:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleMarkers = () => {
    setShowMarkers((prev) => !prev);
  };

  const handleVehicleFilterChange = (e) => {
    const filterText = e.target.value;
    setVehicleFilter(filterText);

    if (filterText) {
      const filtered = vehicles.filter(vehicle =>
        vehicle.plate.toLowerCase().includes(filterText.toLowerCase())||vehicle.name.toLowerCase().includes(filterText.toLowerCase())
      );
      setFilteredVehicles(filtered);
    } else {
      setFilteredVehicles(vehicles);
    }
  };

  // Función para actualizar la dirección cuando se abre el Popup
  const handlePopupOpen = async (latitude, longitude) => {
    setPopupAddress('');
    const address = await getAddrees(latitude, longitude);
    setPopupAddress(address);
  };

  return (
    <div className="history-container">
      <img src={banner} alt="Banner" className="banner-history" />
      <div className="history-content">
        <div className="history-filters">
          <div className="date-inputs">
            <div className="date-input">
              <label htmlFor="start-date">Desde:</label>
              <input
                type="datetime-local"
                id="start-date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="date-input">
              <label htmlFor="end-date">Hasta:</label>
              <input
                type="datetime-local"
                id="end-date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="vehicle-select">
            <input
              type="text"
              placeholder="Filtrar..."
              value={vehicleFilter}
              onChange={handleVehicleFilterChange}
              className="vehicle-filter"
            />
            <select value={selectedVehicle} onChange={handleVehicleChange}>
              <option value="">Seleccionar</option>
              {filteredVehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.traccar_id}>
                  {vehicle.plate}
                </option>
              ))}
            </select>
            <button onClick={handleSubmit}>Consultar</button>
          </div>
        </div>
        {loading ? (
          <div className="loading-spinner">Cargando...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="map-container">
            {showMap && (
              <div className="map-overlay">
                <button className="close-map-button" onClick={toggleMarkers}>
                  {showMarkers ? 'Ocultar Marcadores' : 'Mostrar Marcadores'}
                </button>
                <MapContainer center={mapPosition} zoom={13} style={{ height: '100%', width: '100%' }}>
                  <TileLayer
                    url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                    maxZoom={20}
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                  />
                  <Polyline positions={historyData.map(record => [record.latitude, record.longitude])} />
                  {historyData.map((record, index) => {
                    const isStart = index === 0;
                    const isEnd = index === historyData.length - 1;

                    const markerIcon = L.divIcon({
                      html: isStart
                          ? '<i class="fas fa-dot-circle" style="font-size:24px;color:#0074FF;background-color:white;border-radius:50%;padding:2px;"></i>'
                          : isEnd
                          ? '<i class="fas fa-car" style="font-size:24px;color:red;background-color:white;border-radius:50%;padding:4px;"></i>'
                          : '<i class="fas fa-map-marker-alt" style="font-size:24px;color:red;background-color:none;border-radius:50%;padding:2px;">',
                      className: isStart ? 'start-marker' : isEnd ? 'vehicle-marker' : 'intermediate-marker',
                      iconSize: [24, 24],
                      iconAnchor: [12, 24]
                    });

                    if (showMarkers || isStart || isEnd) {
                      return (
                        <Marker
                          key={index}
                          position={[record.latitude, record.longitude]}
                          icon={markerIcon}
                          eventHandlers={{
                            popupopen: () => handlePopupOpen(record.latitude, record.longitude),
                          }}
                        >
                          <Popup maxWidth={250}>
                            {isStart && 'Inicio del trayecto'}
                            {isEnd && 'Fin del trayecto'}<br />
                            <strong>Mensaje: </strong> {record.message}<br />
                            <strong>Fecha: </strong> {formatDate(record.time)}<br />
                            <strong>Velocidad: </strong> {record.speed} km/h<br />
                            <strong>Distancia: </strong> {record.distance.toFixed(2)} km<br />
                            <strong>Dirección: </strong> {popupAddress || 'Obteniendo dirección...'}
                          </Popup>
                        </Marker>
                      );
                    }
                    return null;
                  })}
                </MapContainer>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Travel