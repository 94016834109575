import React, { useState, useEffect } from 'react';
import banner from '../icons/control.png';
import { getCommands, sendCommand, getDevices } from '../processing/getData';
import './Control.css';

const Control = () => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [commands, setCommands] = useState([]);

  // Cargar vehículos al montar el componente
  useEffect(() => {
    const fetchVehicles = async () => {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        alert('No hay user_id almacenado en localStorage');
        return;
      }

      try {
        const devicesData = await getDevices(userId);
        if (devicesData && devicesData.devices) {
          setVehicles(devicesData.devices);
         
        }
      } catch (error) {
        console.error('Error al obtener los dispositivos:', error);
      }
    };

    fetchVehicles();
  }, []);

  // Manejar el cambio de vehículo y actualizar comandos
  const handleVehicleChange = async (e) => {
    const newSelectedVehicle = e.target.value;
    setSelectedVehicle(newSelectedVehicle);

    const dataHash = localStorage.getItem('data_hash');
    if (!dataHash || !newSelectedVehicle) return;

    try {
      const commandsData = await getCommands(newSelectedVehicle, dataHash);
      if (commandsData && commandsData) {
        setCommands(commandsData);
      }
    } catch (error) {
      console.error('Error al obtener los comandos:', error);
    }
  };

  const handleSendCommand = async (commandType) => {
    const dataHash = localStorage.getItem('data_hash');
    if (!selectedVehicle || !dataHash) {
      alert('Debe seleccionar un vehículo y asegurarse de tener el hash disponible.');
      return;
    }

    try {
      const commandSending= await sendCommand(selectedVehicle, commandType, dataHash);
      alert(`${JSON.stringify(commandSending)}`);
    } catch (error) {
      alert('Error al enviar el comando.');
    }
  };

  return (
    <div className="history-container">
      <img src={banner} alt="Banner" className="banner-history" />
      <div className="control-content">
        <div className="controlfilters">
          <div className="vehicle-select">
            {/*<input
              type="text"
              placeholder="Filtrar vehículos..."
              value={vehicleFilter}
              onChange={handleVehicleFilterChange}
              className="vehicle-filter"
            />*/}
            <select value={selectedVehicle} onChange={handleVehicleChange}>
              <option value="">Seleccionar</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        {selectedVehicle && (
          <div className="control-table-container">
            <table className="control-table">
            <colgroup>
                <col style={{ width: "70%" }} />
                <col style={{ width: "30%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Comando</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {commands.map((command) => (
                  <tr key={command.type}>
                    <td>{command.title.toUpperCase()}</td>
                    <td>
                      <button onClick={() => handleSendCommand(command.type)} style={{
                        backgroundColor: command.color, // Color dinámico del botón
                        color: 'white', // Texto blanco para contraste
                      }}>
                        ENVIAR
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Control;
