import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Menu.css'; // Asegúrate de importar el archivo CSS

const Menu = ({ onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user_id'); // Eliminar el userId del localStorage
    localStorage.removeItem('data_hash');
    onLogout(); // Llamar a la función de logout para limpiar cualquier otra información de sesión
    navigate('/'); // Redirigir a la página de inicio de sesión
  };

  return (
    <div>
      <button className="menu-icon" onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      {isOpen && (
        <div className="menu-background">
          <div className="menu-content">
            <button className="close-icon" onClick={() => setIsOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <ul>
              <li><Link to="/" onClick={() => setIsOpen(false)}>Ubicación</Link></li>
              <li><Link to="/travel" onClick={() => setIsOpen(false)}>Recorrido</Link></li>
              <li><Link to="/history" onClick={() => setIsOpen(false)}>Historial</Link></li>
              <li><Link to="/activity" onClick={() => setIsOpen(false)}>Actividad Diaria</Link></li>
              {/*<li><Link to="/reports" onClick={() => setIsOpen(false)}>Reportes</Link></li>*/}
              {/*<li><Link to="/companies" onClick={() => setIsOpen(false)}>Empresas</Link></li>*/}
              <li><Link to="/control" onClick={() => setIsOpen(false)}>Control de GPS</Link></li>
              <li><button onClick={handleLogout}>Cerrar Sesión</button></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;