import React, { useState, useEffect } from 'react';
import { getID } from '../processing/getData';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (userId) {
      onLogin(userId); // Pasar userId almacenado a la función onLogin
    }
  }, [onLogin]);

  const handleLogin = async () => {
    const response = await fetch(`https://satecolpro.satelitalesdecolombia.com/api/login?email=${username}&password=${password}`, {
      method: 'POST',
    });

    const data = await response.json();
    if (response.ok && data.status === 1) {
      const userData = await getID(username);
      if (userData) {
        localStorage.setItem('user_id', userData.id);
        localStorage.setItem('data_hash',data.user_api_hash);
        onLogin(userData.id);
      } else {
        alert('Error al identificar al Usuario');
      }
    } else {
      alert('Credenciales Inválidas');
    }
  };

  return (
    <div className="background">
      <div className="login-container">
        <h2>Iniciar Sesion</h2>
        <input
          type="email"
          placeholder="Correo Electrónico"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Ingresar</button>
      </div>
    </div>
  );
};

export default Login;
